import { ProductClass } from "../common/prod";

const dic = { cid: "tr", fid: "hr", channel: "tg", pclkid: "gu" };
const extMap = {
  chrome: "pceehdadbjnngdkflkgalipflomjhmei",
  edge: "pceehdadbjnngdkflkgalipflomjhmei",
};
const ev_engine = "https://trk.uso.news/c/";
const domain = "uso.news";
const p = new ProductClass(extMap, dic, ev_engine, domain);

export default p;
