import './style/Reset.css';
import './style/montserrat.css';
import './App.css';
import Hero from './components/Hero';
import Extension from './components/Extension';
import Organizations from './components/Organizations';
import Donate from './components/Donate';
import Footer from './components/Footer';

function App() {
  // We select the element we want to target
  return (
    <div className="App">
      <Hero />
      <Donate />
      <Extension />
      <Organizations />
      {/* <Report /> */}
      {/* <Carousel /> */}
      {/* <SimpleSlider /> */}
      <button className="scrollToTopBtn">
        <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <polygon fill="#fff" points="12 9.414 4.707 16.707 3.293 15.293 12 6.586 20.707 15.293 19.293 16.707" />
        </svg>
      </button>
      <Footer />
    </div>
  );
}

export default App;
