import React from 'react';
//import ArrowRight from '../image/arrow-right.png';

const Test = () => {
    let url = "https://www.ifrc.org/taxonomy/term/427";
    let url_2 = "https://www.pah.org.pl/en/-";
    let url_3 = "https://www.comebackalive.in.ua/";
    return (
      <div className="container">
        <h3 className="title-orgaz">We are giving to multiple organizations</h3>
        <div className="flex-boxes">
          <a className="organize-box orga-1" href={url} target="_blank"  rel="noreferrer">
          </a>
          <a className="organize-box orga-2" href={url_2} target="_blank"  rel="noreferrer">
          </a>
          <a className="organize-box orga-3" href={url_3} target="_blank"  rel="noreferrer">
          </a>
        </div>
        {/* <div className="flex justify-end items-end">
          <button className="flex gap-3 items-center cursor-orgaz">
            <label className="text-xl" style={{ cursor: `pointer` }}>
              View all organizations
            </label>{" "}
            <img src={ArrowRight} alt="arrow-right" />
          </button>
        </div> */}
      </div>
    );
  };

export default Test;
