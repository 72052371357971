import React from 'react';
import '../style/extension.css';
import Gif from '../image/giphy.gif';
import p from '../constants/prod';

const Extension = () => {
    return (
        <div className="container">
            <div className="flex-wrapper">
                <div className="flex-left">
                    <h1>Get the Support Ukraine extension</h1>
                    <p>You also can help Ukraine Support Organizations during these difficult times.
                     All you have to do is add our search extension, free of charge, and start looking for whatever you want through
                      your address bar or a new tab page. You see how much money your searches are generating for the cause in real-time.</p>
                    <button onClick={() => {p.Click();}}>Free Download</button>
                </div>
                <div className="gif">
                <img className="gify" src={Gif} alt="gif" />
                </div>
            </div>
        </div>
    );
};

export default Extension;