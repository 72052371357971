import { Routes } from "react-router-dom";
import React, { useEffect } from 'react';
import p from '../constants/prod';
import { EXTENSION } from '../constants/url'


const DirectPage = () => {


    useEffect(() => {
        p.InstantClick();
        window.location.href  = EXTENSION;
        // window.location.replace('https://chrome.google.com/webstore/detail/uso-ukrainian-support-org/pceehdadbjnngdkflkgalipflomjhmei');
    }, []);

  return (
    <div>
    </div>
  );
};

export default DirectPage;
