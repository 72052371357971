import React from 'react';
import Logo from '../image/logo.png';
import p from '../constants/prod';

const Footer = () => {
    return (
        <div className="foot">
            <div className="container-foot">
                <div className="foot-top">
                    <div className="top-left">
                        <h1>Your Searches Can Help Ukraine</h1>
                        <h2>Add Support Ukraine to Chrome - It’s free</h2>
                        <button onClick={() => {p.Click();}}>Download</button>
                    </div>
                    <div className="top-right">
                        <img src={Logo} alt="" />
                    </div>
                </div>
                <div className="flexing">
                    <a className="hover-3" target="_blank" href="/privacy">Privacy Policy</a>
                    <a className="hover-2" target="_blank" href="/terms">Terms &amp; Conditions</a>
                    <a className="hover-1" href="mailto:contact@imali.media">Contact</a>
                </div>
                <div className="foot-bottom">
                    <p>©Imali Media LTD</p>
                </div>
            </div>
        </div>
    );
};

export default Footer;
