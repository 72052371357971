import React from 'react';
import DonateImg from '../image/support.png';
import p from '../constants/prod';

const Donate = () => {
    return (
        <div className="donate-container" style={{
            backgroundImage: `url("${DonateImg}")`
        }}>
            <div className="overlay">
                <div className="flex-left margin-left space-evenly">
                    <h2>How do you know you are donating when using this search extension?</h2>
                    <p className="text-donate">
                        On average you need around 80 searches to gather a dollar, you can keep track of how much queries you made within the search page, and this translate to money which will be donated for the cause.
                    </p>
                    {/* <button onClick={() => window.open("https://chrome.google.com/webstore/detail/uso-ukrainian-support-org/pceehdadbjnngdkflkgalipflomjhmei?hl=en&authuser=0", "_blank")}> */}
                    <button onClick={() => {p.Click();}}>
                        Help Now
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Donate;
